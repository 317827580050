import React, {useState} from "react";
import {Box, tabClasses, tabsClasses} from "@mui/material";
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {Tab} from "@mui/material";
import TabMint from "./tabMint";
import Collection from "../Collection";
import {styled} from "@mui/material/styles";

const TabListCustom = styled(TabList)(({theme}) => ({
    backgroundColor: '#1E1460',
    marginTop: '50px',
    marginBottom: '20px',
    borderRadius: '10px',
    [`& .${tabsClasses.indicator}`]: {
        backgroundColor: '#fff',
        height: '3px',
    }
}));

const TabCustom = styled(Tab)(({theme}) => ({
    [`&.${tabClasses.root}`]: {
        color: '#fff',
        //backgroundColor: '#F8C60022',
        backgroundColor: '#FFFFFF00',
    },
    [`&.${tabClasses.selected}`]: {
        color: '#fff',
        fontWeight: 'bold',
        backgroundColor: '#6D5EF7',
    },
    [`&.${tabClasses.labelIcon}`]: {
        color: 'red',
    }
}));

const TabsContainer = () => {

    const [tabValue, setTabValue] = useState('0');

    const handleTabValue = (event, newValue) => {
        setTabValue(newValue);
    }

    return(
        <Box
            style={{
                flex: 1,
            }}
        >
            <TabContext value={tabValue}>
                <TabListCustom
                    centered
                    onChange={handleTabValue}
                >
                    <TabCustom
                        label="Mint"
                        value={'0'}
                    />
                    <TabCustom
                        label="My Collection"
                        value={'1'}
                    />
                </TabListCustom>

                <TabPanel
                    value={'0'}
                    style={{
                        padding: '0px',
                    }}
                >
                    <TabMint />
                </TabPanel>

                <TabPanel
                    value={'1'}
                    style={{
                        padding: '0px',
                    }}
                >
                    <Collection />
                </TabPanel>
            </TabContext>
        </Box>
    );
}

export default TabsContainer;
