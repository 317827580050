import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    avalancheConnectedState: false,
    accountsState: [],
}

const metamaskSlice = createSlice({
    name: 'metamask',
    initialState,
    reducers: {
        setAvalancheConnectedRdx: (state, action) => {
            state.avalancheConnectedState = action.payload;
        },
        setAccountsRdx: (state, action) => {
            state.accountsState = action.payload;
        },
    }
});

export const {setAvalancheConnectedRdx, setAccountsRdx} = metamaskSlice.actions;

export default metamaskSlice.reducer;
