import './App.css';
import Header from "./components/Header";
import {Container} from "@mui/material";
import TitleCustom from "./components/Title";
import Prices from "./components/Prices";
import Footer from "./components/Footer";
import TabsContainer from "./components/Tabs";

function App() {
    return (
        <Container
            maxWidth={'lg'}
            className="App"
            style={{
                display: 'flex',
            }}
        >
            <Header />

            <TabsContainer />

            <Prices />

            <Footer />
        </Container>
    );
}

export default App;
