import React from "react";
import "./prices.css";
import LogoOpenSea from "../../assets/imgs/Logo_OpenSea_White.svg";

const Prices = () => {
    return (
        <>
            <div className="prices-container">
                <h1
                    style={{
                        color: '#F0B12B',
                        cursor: 'pointer',
                    }}
                >
                    Rounds & Prices
                </h1>

                <div
                    className={'prices-rounds-container'}
                >
                    <h4>
                        <span>Round 1:</span> Private sale has a 20% discount on the public sale price.
                    </h4>
                    <h4>
                        <span>Round 2:</span> Private sale (whitelist) has a 10% discount on the public sale price.
                    </h4>
                    <h4>
                        <span>Round 3:</span> Public sale.
                    </h4>
                </div>
            </div>

            <div
                className={'kalao-container'}
            >
                <h4>
                    Find our collection also at
                </h4>
                <a
                    href="https://opensea.io/collection/zoorbis-cards"
                    target="_blank"
                >
                    <img
                        src={LogoOpenSea}
                        alt="openSea Logo"
                    />
                </a>
            </div>
        </>
    )
}

export default Prices;
