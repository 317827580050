import {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, Typography} from "@mui/material";
import "./mint.css";
import {Add, Remove} from "@mui/icons-material";
import {useSelector} from "react-redux";
import CClient from "../../assets/abis/ZoorbisFactory.json";
import Swal from "sweetalert2";
import Web3Modal from "web3modal";
import axios from "axios";
import {ethers} from "ethers";

const MintItem = ({rarity}) => {

    // Redux
    const metamaskStore = useSelector(state => state.rootRdx.metamaskRdx);

    const [mintAmount, setMintAmount] = useState(1);
    const [loadingMint, setLoadingMint] = useState(false);
    const [priceItem, setPriceItem] = useState(0);

    const handleMintAmountAdd = () => {
        if (mintAmount < 5) {
            setMintAmount(mintAmount + 1);
        }
    }

    const handleMintAmountRemove = () => {
        if (mintAmount > 1) {
            setMintAmount(mintAmount - 1);
        }
    }

    const colors0 = [
        '#000000',
        '#02732A',
        '#0339A6',
        '#A6059B',
        '#F2C53D',
    ];

    const colors = [
        '#000',
        '#fff',
        '#fff',
        '#fff',
        '#fff',
    ];

    const colorsTextStroke = [
        '#000000',
        '#FFFFFFCC',
        '#FFFFFFCC',
        '#FFFFFFCC',
        '#000000',
    ];

    const colorsClass = [
        'black',
        'green',
        'blue',
        'purple',
        'gold',
    ];

    const rarityName = [
        'undefined',
        'common',
        'rare',
        'mythic',
        'legendary',
    ];

    const rarityImageName = [
        'undefined',
        'sobre-verde.png',
        'sobre-azul.png',
        'sobre-morado.png',
        'sobre-dorado.png',
    ];

    const getPrice = async () => {
        const urlTMP = process.env.REACT_APP_API_BASE_URL + "/get/price";
        const response = await axios.post(urlTMP, {
            'zoorbisRarity': rarity,
        }, {
            headers: {
                'content-type': 'application/json',
            }
        });

        if (response.status === 200) {
            setPriceItem(Number(response.data));
        } else {
            setPriceItem(Number(0));
        }
    }

    useEffect(() => {
        getPrice().then(() => {
        });
    }, []);

    const generateZoorbisData = async () => {
        const urlTMP = process.env.REACT_APP_API_BASE_URL + "/generate/data";
        const response = await axios.post(urlTMP, {
            'zoorbisRarity': rarityName[rarity],
            'zoorbisQuantity': mintAmount
        }, {
            headers: {
                'content-type': 'application/json',
            }
        });

        if (response.status === 200) {
            return response.data;
        } else {
            return null;
        }
    }

    const buyZoorbis = async () => {
        setLoadingMint(true);
        try {
            const web3Modal = new Web3Modal();
            const connection = await web3Modal.connect();
            const provider = new ethers.providers.Web3Provider(connection);
            const signer = provider.getSigner();

            const dataToMint = await generateZoorbisData();
            if (dataToMint === null) {
                await Swal.fire({
                    title: 'Error',
                    text: 'Error generating data',
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                return;
            } else {
                console.log("Data OK");
            }

            let contract = new ethers.Contract(process.env.REACT_APP_CONTRACT_FACTORY_ADDRESS, CClient.abi, signer);

            let tx = await contract.buyZoorbis(
                metamaskStore.accountsState[0],
                mintAmount,
                dataToMint,
                rarity,
                {
                    value: await ethers.utils.parseUnits((priceItem * mintAmount).toString(), 18)
                }
            );
            await tx.wait();

            let transactionMined = false;
            while (!transactionMined) {
                let transactionReceipt = await provider.getTransactionReceipt(tx.hash);

                if (transactionReceipt !== null) {
                    if (transactionReceipt.status === 1) {
                        transactionMined = true;

                        await Swal.fire({
                            title: 'Transaction is finished',
                            html: '<br/><b>Transaction Hash:</b> ' + tx.hash + '<br/><b>Transaction Gas Used:</b> ' + transactionReceipt.gasUsed,
                            icon: 'success',
                        });

                        setLoadingMint(false);
                    }
                }
            }

        } catch (error) {
            console.log('Catch error: ', error);
            await Swal.fire({
                title: 'Error',
                text: error.message,
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            setLoadingMint(false);
        }
    }

    return (
        <div className={'mint-item'}>
            <Grid
                container
                className={'mint-grid-container'}
            >
                <div
                    className={'clouds-bg clouds-' + rarity}
                />

                <Grid
                    item xs={12} md={4}
                    style={{
                        position: 'relative',
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            backgroundColor: '#ffffffdd',
                            width: '100%',
                            height: '40%',
                            zIndex: -1,
                            borderRadius: 20,
                            top: '50%',
                            transform: 'translateY(-50%)',
                        }}
                    />

                    <img
                        src={require('../../assets/imgs/' + rarityImageName[rarity])}
                        alt="mint-item"
                        className={'mint-item-img'}
                    />
                </Grid>

                <Grid
                    item xs={12} md={4}
                    sx={{
                        zIndex: '1',
                    }}
                >
                    <div>
                        <Typography
                            variant="body1"
                            style={{
                                color: 'white',
                                textAlign: 'justify',
                            }}
                        >
                            Select the amount of <span
                            className={'marked-text ' + colorsClass[rarity]}>{rarityName[rarity]}</span> zoorbis
                            packages to mint
                        </Typography>
                    </div>

                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            marginTop: '20px',
                            marginBottom: '20px',
                            width: '100%'
                        }}
                    >
                        <Button
                            onClick={handleMintAmountRemove}
                            variant={'contained'}
                            className={'mint-item-button'}
                        >
                            <Remove
                                style={{
                                    color: colors[rarity],
                                }}
                            />
                        </Button>

                        <input
                            type="text"
                            value={mintAmount}
                            disabled={true}
                            style={{
                                fontWeight: 'bold',
                                textAlign: 'center',
                                fontSize: '2rem',
                                maxWidth: '50%',
                                backgroundColor: '#1E1460',
                                color: 'white',
                            }}
                        />

                        <Button
                            onClick={handleMintAmountAdd}
                            variant={'contained'}
                            className={'mint-item-button'}
                        >
                            <Add
                                style={{
                                    color: colors[rarity],
                                }}
                            />
                        </Button>
                    </div>

                    {
                        (metamaskStore.avalancheConnectedState && metamaskStore.accountsState[0]) ?
                            <Box>
                                <div>
                                    {
                                        loadingMint ?
                                            <CircularProgress
                                                style={{
                                                    color: colors[rarity],
                                                }}
                                            />
                                            :
                                            <Button
                                                variant={'contained'}
                                                fullWidth
                                                className={'btn-call-mint ' + colorsClass[rarity]}
                                                disabled={
                                                    metamaskStore.avalancheConnectedState === false ||
                                                    priceItem === 0 ||
                                                    isNaN(priceItem)
                                                }
                                                style={{
                                                    backgroundColor: '#6D5EF7',
                                                }}
                                                sx={{
                                                    '&disabled': {
                                                        backgroundColor: 'gray'
                                                    }
                                                }}
                                                onClick={async () => {
                                                    await buyZoorbis();
                                                }}
                                            >
                                                Mint
                                            </Button>
                                    }
                                </div>

                                <div>
                                    <Typography
                                        variant="body1"
                                        style={{
                                            color: 'white',
                                            textAlign: 'right',
                                            marginTop: '10px',
                                        }}
                                    >
                            <span
                                className={'marked-text ' + colorsClass[rarity]}
                                style={{
                                    letterSpacing: '0.1rem !important',
                                }}
                            >
                                {(priceItem * mintAmount).toFixed(4)} MATIC
                            </span>
                                    </Typography>
                                </div>
                            </Box>
                            :
                            <Box>
                                <Typography
                                    style={{
                                        backgroundColor: '#6D5EF7',
                                        fontFamily: '"Roboto", san-serif, serif',
                                        fontWeight: 500,
                                        color: colors[rarity],
                                        fontSize: '1.5rem',
                                        cursor: 'help',
                                        borderRadius: '10px',
                                    }}
                                >
                                    Please connect<br/>your Metamask!
                                </Typography>
                            </Box>
                    }
                </Grid>
            </Grid>
        </div>
    )
}

export default MintItem;
