import React, {useEffect, useState} from "react";
import {Box, Button, CircularProgress, Grid, IconButton, Paper, Tooltip, Typography} from "@mui/material";
import "./collection.css";
import Moralis from "moralis";
import {EvmChain} from "@moralisweb3/evm-utils";
import {Logout, Refresh} from "@mui/icons-material";
import {useSelector} from "react-redux";

const Collection = () => {

    // Redux
    const metamaskStore = useSelector(state => state.rootRdx.metamaskRdx);

    const [isAuthenticated, setIsAuthenticated] = useState(true);
    const [listCollection, setListCollection] = useState([]);
    const [loadingCollection, setLoadingCollection] = useState(false);

    useEffect(() => {
        if (metamaskStore.accountsState[0] !== undefined && metamaskStore.accountsState[0] !== null && metamaskStore.accountsState[0] !== '') {
            setIsAuthenticated(true);
        } else {
            setIsAuthenticated(false);
        }
    }, [metamaskStore.accountsState]);

    const getAllNfts = async () => {
        setLoadingCollection(true);
        //console.log('Wallet Collection: ' + metamaskStore.accountsState[0]);

        const urlTmp = process.env.REACT_APP_API_BASE_URL + '/get/nfts/by/wallet';
        await fetch(urlTmp, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                walletUser: metamaskStore.accountsState[0],
            })
        })
            .then(response => response.json())
            .then(data => {
                //console.log('Success:', data);
                setListCollection(data);
            })
            .catch((error) => {
                console.error('Error:', error);
                setListCollection([]);
            });

        setLoadingCollection(false);
    }

    useEffect(() => {
        if (isAuthenticated) {
            getAllNfts().then(() => {
            })
        }
    }, [isAuthenticated]);

    return (
        <Box
            className={'collection-container'}
        >
            <h1
                style={{
                    color: '#fff',
                    cursor: 'pointer',
                }}
            >
                My Collection
            </h1>

            {
                !isAuthenticated &&
                <Box
                    style={{
                        marginTop: '50px',
                    }}
                >
                    <Button
                        className={'button-header'}
                        variant={'contained'}
                        onClick={() => {}}
                    >
                        <Typography
                            style={{
                                color: '#fff',
                            }}
                        >
                            Please connect your wallet
                        </Typography>
                    </Button>
                </Box>
            }

            {
                isAuthenticated &&
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        width: '100%',
                    }}
                >
                    <Box
                        style={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'flex-end',
                            marginBottom: '30px',
                        }}
                    >
                        <Tooltip title={'Refresh collection'}>
                            <IconButton
                                onClick={getAllNfts}
                                size={'small'}
                                style={{
                                    color: '#fff',
                                    marginRight: '10px',
                                }}
                            >
                                <Refresh/>
                            </IconButton>
                        </Tooltip>
                    </Box>

                    {
                        loadingCollection ?
                            <Box>
                                <h5
                                    style={{
                                        color: '#fff',
                                    }}
                                >
                                    Loading...
                                </h5>
                                <CircularProgress
                                    style={{
                                        color: '#fff',
                                        height: '20px',
                                        width: '20px',
                                        marginRight: '10px',
                                    }}
                                />
                            </Box>
                            :
                            <Grid
                                container
                                style={{
                                    width: '100%',
                                    display: 'flex',
                                    justifyContent: 'space-around',
                                }}
                            >
                                {
                                    listCollection.map((item, index) => {
                                        return (
                                            <Grid
                                                item
                                                key={index}
                                                xs={12} md={3} xl={3}
                                                style={{
                                                    padding: '10px',
                                                    borderRadius: '4px',
                                                    cursor: 'pointer',
                                                }}
                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: '#ffffff55',
                                                    }
                                                }}
                                            >
                                                <Paper
                                                    style={{
                                                        height: '100%',
                                                        width: '100%',
                                                        position: 'relative',
                                                    }}
                                                >
                                                    <img
                                                        src={item.image.includes("ipfs.moralis") ? item.image : 'https://gateway.moralisipfs.com/ipfs/' + item.image}
                                                        alt={item.name}
                                                        style={{
                                                            width: '100%',
                                                            height: 'calc(100% - 30px)',
                                                            borderTopLeftRadius: '4px',
                                                            borderTopRightRadius: '4px',
                                                            borderBottomLeftRadius: '4px',
                                                            borderBottomRightRadius: '4px',
                                                            paddingBottom: '30px',
                                                        }}
                                                    />

                                                    <Box
                                                        style={{
                                                            position: 'absolute',
                                                            bottom: '0',
                                                            left: '0',
                                                            right: '0',
                                                            width: '100%',
                                                            height: '30px',
                                                            background: '#6D5EF7',
                                                            borderBottomLeftRadius: '4px',
                                                            borderBottomRightRadius: '4px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}
                                                    >
                                                        <h5
                                                            style={{
                                                                margin: '0',
                                                                color: '#FFF',
                                                                fontWeight: 'bold',
                                                                letterSpacing: '0.5px',
                                                            }}
                                                        >
                                                            {item.name}
                                                        </h5>
                                                    </Box>
                                                </Paper>
                                            </Grid>
                                        )
                                    })
                                }
                            </Grid>
                    }
                </Box>
            }
        </Box>
    );
}

export default Collection;
