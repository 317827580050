import React from "react";
import MintItem from "../Mint";
import TitleCustom from "../Title";

const TabMint = () => {
    return (
        <>
            <TitleCustom />

            <MintItem
                rarity={1}
            />

            <MintItem
                rarity={2}
            />

            <MintItem
                rarity={3}
            />

            <MintItem
                rarity={4}
            />
        </>
    );
}

export default TabMint;
