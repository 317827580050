import React, {useEffect, useState} from 'react';
import {Box, Button, Container, Grid, IconButton, Typography} from "@mui/material";
import "./header.css";
import {MenuOutlined} from "@mui/icons-material";
import MetaMaskOnboarding from "@metamask/onboarding";
import Swal from 'sweetalert2';
import {useSelector, useDispatch} from "react-redux";
import {setAccountsRdx, setAvalancheConnectedRdx} from "../../redux/reducers/metamaskSlicer";

import LogoOpenSea from "../../assets/imgs/Logo_OpenSea_Blue.svg";

const AVALANCHE_MAINNET_PARAMS = {
    chainId: '0x89',
    chainName: 'Polygon Mainnet',
    nativeCurrency: {
        name: 'Polygon',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://polygon-rpc.com/'],
    blockExplorerUrls: ['https://polygonscan.com/']
};

const AVALANCHE_TESTNET_PARAMS = {
    chainId: '0x13881',
    chainName: 'Mumbai Testnet',
    nativeCurrency: {
        name: 'Mumbai Testnet',
        symbol: 'MATIC',
        decimals: 18
    },
    rpcUrls: ['https://rpc-mumbai.maticvigil.com/'],
    blockExplorerUrls: ['https://mumbai.polygonscan.com/']
};

const Header = () => {

    let menuItems = null;
    let menuContainer = null;

    useEffect(() => {
        menuItems = document.querySelector('#overlay');
        menuContainer = document.querySelector('.menu-container');
    });

    // Redux
    const metamaskStore = useSelector(state => state.rootRdx.metamaskRdx);
    const dispatch = useDispatch();

    // Metamask
    const [accounts, setAccounts] = useState([]);
    const [currentChainId, setCurrentChainId] = useState(null);
    const [connectedToPolygon, setConnectedToPolygon] = useState(false);
    const [metaMaskOnboarding] = useState(new MetaMaskOnboarding());
    const [showBtnMint, setShowBtnMint] = React.useState(false);
    const [metamaskBtnState, setMetamaskBtnState] = useState('');

    const isMobileDevice = () => {
        return 'ontouchstart' in window || 'onmsgesturechange' in window;
    }

    const POLYGON_NETWORK_PARAMS = process.env.REACT_APP_PRODUCTION_READY === 'true' ? AVALANCHE_MAINNET_PARAMS : AVALANCHE_TESTNET_PARAMS;

    const isPolygonChain = (chainId) => {
        return chainId.toLowerCase() === POLYGON_NETWORK_PARAMS.chainId.toLowerCase();
    }

    const connectMobile = async () => {
        if (!connectedToPolygon) {
            if (!window.ethereum) {
                if (isMobileDevice()) {
                    const {value} = await Swal.fire({
                        title: 'Mobile device detected',
                        text: 'Do you want to open on Metamask App?',
                        icon: 'info',
                        showCancelButton: true,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Yes, switch to metamask app!',
                        cancelButtonText: 'No, cancel!',
                        reverseButtons: true
                    });

                    if (value) {
                        const reactApp = 'koins-front-sioaf.ondigitalocean.app';
                        window.location.href = `https://metamask.app.link/dapp/${reactApp}`;
                    }
                }
            }
        }
    }

    const connectMetaMask = () => {
        if (window.ethereum) {
            window.ethereum
                .request({method: 'eth_requestAccounts'})
                .then((accounts) => {
                    setAccounts(accounts);
                    dispatch(setAccountsRdx(accounts));
                    setMetamaskBtnState(accounts[0].substring(0, 5) + '...' + accounts[0].substring(accounts[0].length - 3));
                    let polygonChain = isPolygonChain(window.ethereum.chainId);
                    if (polygonChain) {
                        setConnectedToPolygon(true);
                        dispatch(setAvalancheConnectedRdx(true));
                        localStorage.setItem('avalancheChain', 'true');
                    } else {
                        setConnectedToPolygon(false);
                        dispatch(setAvalancheConnectedRdx(false));
                        localStorage.setItem('avalancheChain', 'false');
                    }
                });
        }
    }

    useEffect(() => {
        if (accounts !== null && accounts !== undefined && accounts.length > 0) {
            setMetamaskBtnState(accounts[0].substring(0, 5) + '...' + accounts[0].substring(accounts[0].length - 3));
        }
    }, [accounts]);

    useEffect(() => {
        if (localStorage.getItem('avalancheChain') === 'true') {
            connectMetaMask();
        }
    }, []);

    const switchToPolygonChain = () => {
        window.ethereum.autoRefreshOnNetworkChange = true;
        window.ethereum
            .request({
                method: 'wallet_addEthereumChain',
                params: [POLYGON_NETWORK_PARAMS]
            });
    }

    useEffect(() => {
        if (MetaMaskOnboarding.isMetaMaskInstalled()) {
            metaMaskOnboarding.stopOnboarding();
        } else {
            if (!isMobileDevice()) {
                // Swal fire with yes and no button and confirm with callback
                Swal.fire({
                    title: 'Install MetaMask?',
                    text: 'You need to install MetaMask to use this app',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, install it!',
                    cancelButtonText: 'No, cancel'
                }).then((result) => {
                    if (result.value) {
                        metaMaskOnboarding.startOnboarding();
                    }
                })
            } else {
                connectMobile().then(() => {
                });
            }
        }
    }, [metaMaskOnboarding]);

    useEffect(() => {
        if (window.ethereum) {
            console.log('MetaMask is installed');
            window.ethereum.autoRefreshOnNetworkChange = true;

            // On account change
            window.ethereum.on('accountsChanged', (accounts) => {
                setAccounts(accounts);
            });

            // On chain changed
            window.ethereum.on('chainChanged', () => {
                window.location.reload();
                let polygonChain = isPolygonChain(window.ethereum.chainId);
                if (polygonChain) {
                    setConnectedToPolygon(true);
                    dispatch(setAvalancheConnectedRdx(true));
                    localStorage.setItem('avalancheChain', 'true');
                } else {
                    setConnectedToPolygon(false);
                    dispatch(setAvalancheConnectedRdx(false));
                    localStorage.setItem('avalancheChain', 'false');
                }
            });

            // On connect
            window.ethereum.on('connect', (connectInfo) => {
                const chainId = connectInfo.chainId;
                setCurrentChainId(chainId);
                if (isPolygonChain(chainId)) {
                    console.log('Connected to Avalanche chain');
                    setConnectedToPolygon(true);
                    dispatch(setAvalancheConnectedRdx(true));
                } else {
                    console.log('Connected to unknown chain');
                    setConnectedToPolygon(false);
                    dispatch(setAvalancheConnectedRdx(false));
                }
            });
        }
    }, []);

    useEffect(() => {
        if (connectedToPolygon && accounts.length > 0 && accounts[0] !== '0x0000000000000000000000000000000000000000' && accounts[0] != null) {
            setShowBtnMint(true);
        } else {
            setShowBtnMint(false);
        }
    }, [connectedToPolygon, accounts[0]]);

    // ****************************************************************************************************************

    const buttonsInfo = [
        {
            name: "Home",
            link: "https://zoorbis.com/"
        },
        {
            name: "Mint now!",
            link: "https://mint.zoorbis.com/"
        },
        {
            name: "Ecosystem",
            link: "https://staking.zoorbis.com/"
        },
        {
            name: "Whitepaper",
            link: "https://docs.zoorbis.com/"
        }
    ];

    return (
        <Container
            className="container-header"
            maxWidth={'lg'}
        >
            <div className={'header-back-1'}/>
            <div className={'header-back-2'}/>
            <Grid
                container
                className={'header'}
            >
                <Grid
                    item xs={12} md={2}
                    className={'header'}
                >
                    <img
                        src={require('../../assets/imgs/logo.png')}
                        alt="logo"
                        className={'logo-header'}
                    />
                </Grid>

                {
                    buttonsInfo.map((button, index) => {
                        return (
                            <Grid
                                item xs={12} md={2}
                                key={index}
                                className={'header'}
                                style={{
                                    padding: 10
                                }}
                            >
                                <Button
                                    className={'button-header'}
                                    variant={'contained'}
                                    href={button.link}
                                    target={'_blank'}
                                >
                                    {button.name}
                                </Button>
                            </Grid>
                        );
                    })
                }

                <Grid
                    item xs={12} md={2}
                    className={'header'}
                    style={{
                        paddingLeft: 20
                    }}
                >
                    <div
                        className={'button-mint'}
                        onClick={() => {
                            if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
                                metaMaskOnboarding.startOnboarding();
                            }
                            connectMetaMask();
                            if (!connectedToPolygon) {
                                switchToPolygonChain();
                            }
                        }}
                    >
                        {
                            (connectedToPolygon && accounts[0]) ?
                                <div>
                                    {
                                        metamaskBtnState
                                    }
                                </div>
                                :
                                <div
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center'
                                    }}
                                >
                                    <img
                                        src={require('../../assets/imgs/billetera.png')}
                                        alt="logo"
                                        style={{
                                            width: 20,
                                            height: 20,
                                            marginRight: 10
                                        }}
                                    />

                                    <Typography>
                                        Connect Wallet
                                    </Typography>
                                </div>
                        }
                    </div>
                </Grid>
            </Grid>

            <Grid
                container
                className={'header mobile'}
            >
                <Grid
                    item sm={2} xs={6}
                    className={'header mobile'}
                >
                    <img
                        src={require('../../assets/imgs/logo.png')}
                        alt="logo"
                        className={'logo-header'}
                    />
                </Grid>
            </Grid>

            <IconButton
                className={'menu'}
                onClick={(e) => {
                    menuItems.classList.toggle('open');
                    menuContainer.classList.toggle('full-menu');
                }}
            >
                <MenuOutlined
                    fontSize={'large'}
                    color={'primary'}
                    style={{
                        color: 'white',
                        fontSize: '3.5rem'
                    }}
                />
            </IconButton>

            <div className="menu-container" id="toggle">
            </div>

            <div className="overlay" id="overlay">
                <nav className="overlay-menu">
                    <ul>
                        {
                            buttonsInfo.map((button, index) => {
                                return (
                                    <li key={index}>
                                        <a
                                            href={button.link}
                                            target={'_blank'}
                                        >
                                            {button.name}
                                        </a>
                                    </li>
                                );
                            })
                        }
                        <li
                            style={{
                                border: '1px solid white',
                                marginTop: '20px',
                                width: 'auto',
                            }}
                        >
                            <a
                                href={'#'}
                            >
                                {
                                    (connectedToPolygon && accounts[0]) ?
                                        <>
                                            {
                                                metamaskBtnState
                                            }
                                        </>
                                        :
                                        <>
                                            Connect Wallet
                                        </>
                                }
                            </a>
                        </li>
                    </ul>
                </nav>
            </div>
        </Container>
    )
        ;
}

export default Header;
