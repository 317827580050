import React from "react";
import PropTypes from 'prop-types';
import {Step, StepLabel, Stepper} from "@mui/material";
import {styled} from '@mui/material/styles';
import StepConnector, {stepConnectorClasses} from '@mui/material/StepConnector';
import {Check} from "@mui/icons-material";
import "./title.css";

const ColorlibConnector = styled(StepConnector)(({theme}) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#F0B12B',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundColor: '#F0B12B',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor:'#47318B',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')(({theme, ownerState}) => ({
    backgroundColor: '#271758',
    zIndex: 1,
    color: '#4B309B',
    fontWeight: 600,
    cursor: 'pointer',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    transition: 'all 0.2s ease-in-out',
    ...(ownerState.active && {
        backgroundColor: '#F0B12B',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
        color: '#fff',
    }),
    ...(ownerState.completed && {
        backgroundColor: '#F0B12B',
    }),
    '&:hover': {
        boxShadow: '0 0 15px #F8C600',
        transition: 'all 0.3s',
    }
}));

function ColorlibStepIcon(props) {
    const {active, completed, className} = props;

    const icons = {
        1: <Check />,
        2: <p>2</p>,
        3: <p>3</p>,
    };

    return (
        <ColorlibStepIconRoot ownerState={{completed, active}} className={className}>
            {icons[String(props.icon)]}
        </ColorlibStepIconRoot>
    );
}

ColorlibStepIcon.propTypes = {
    /**
     * Whether this step is active.
     * @default false
     */
    active: PropTypes.bool,
    className: PropTypes.string,
    /**
     * Mark the step as completed. Is passed to child components.
     * @default false
     */
    completed: PropTypes.bool,
    /**
     * The label displayed in the step icon.
     */
    icon: PropTypes.node,
};

const steps = ['', '', ''];

const TitleCustom = () => {
    return (
        <div className="title-container">
            <h1
                style={{
                    color: '#fff',
                    cursor: 'pointer',
                }}
            >
                Mint your Zoorbis NFT
            </h1>
            <h4
                className={'subtitle-mint'}
            >
                and join the community
            </h4>

            <h2
                className={'text-round'}
            >
                Current round
            </h2>

            <Stepper
                alternativeLabel
                activeStep={0}
                connector={<ColorlibConnector/>}
                style={{
                    width: '100%',
                }}
            >
                {steps.map((label, idx) => (
                    <Step key={label + idx}>
                        <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
        </div>
    );
}

export default TitleCustom;
